// Import node_modules
import 'jquery'
import 'popper.js'
import 'bootstrap-sass'

// Import local CSS that should be built on production as separate files
import '../../stylesheets/landing_page/landing_page';
import '../../stylesheets/emails';

// Import local images
const images = require.context('../../images', true)
const imagePath = (name) => images(name, true)


// Collapse Navbar
function navbar_collapse() {
  if ($("#main-navbar").offset().top > 100) {
    $('#collapse-navbar').collapse('hide');
    $("#main-navbar").addClass("landing-page-navbar-shrink");
  } else {
    $("#main-navbar").removeClass("landing-page-navbar-shrink");
  }
};

$(function() {
  // Activate scrollspy to add active class to navbar items on scroll
  $('body').scrollspy({
    target: '#main-navbar',
    offset: 66
  });
  
  // Smooth scrolling using jQuery
  $('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function() {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      
      if (target.length) {
        $('html, body').animate({
          scrollTop: (target.offset().top - 60)
        }, 600);
        return false;
      }
    }
  });
  
  // Closes responsive menu when a scroll trigger link is clicked
  $('.js-scroll-trigger').click(function() {
    $('#collapse-navbar').collapse('hide');
  });
  
  // Collapse now if page is not at top
  navbar_collapse();
  
  // Collapse the navbar when page is scrolled
  $(window).scroll(function() {
    navbar_collapse();
  });
});
